import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import axios from "axios";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import cookie from "react-cookies";
import Swal from "sweetalert2";
import { GET_CUSTOMER_DETAILS, GET_PRODUCT_LIST } from "../../actions";
import { apiUrl, unquieID, baseUrl } from "../Settings/Config";
import {
  hideLoader,
  showAlert,
  showLoader,
  showPriceSeparate,
  showLoaderLst,
  hideLoaderLst,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import googlePay from "../../common/images/payment/google-pay.svg";
import amexPay from "../../common/images/payment/american-express.svg";
import applePay from "../../common/images/payment/apple_logo.svg";
import atome from "../../common/images/payment/atome.svg";
import paynow from "../../common/images/payment/PayNowLogo.svg";
import grabpay from "../../common/images/payment/GrabPay.svg";
import mastercard from "../../common/images/payment/mastercard.svg";
import visa from "../../common/images/payment/visa.svg";
import mswipe from "../../common/images/payment/mswipe.svg";
import cellphone1 from "../../common/images/cellphone1.svg";
import walletlight from "../../common/images/wallet.svg";
var qs = require("qs");
class Checkout extends Component {
  constructor(props) {
    super(props);
    var UserId = cookie.load("UserId") || "";
    this.state = {
      bottompopup: "regphone",
      voucherList: [],
      typeofCreditRedeem: 1,
      billPayAmount: "",
      current_page: "Direct Pay",
      checkoutType: "BillPay",
      clientLogo: "",
      backLink: "",
      UserId: UserId,
      customerData: [],
      plan_data: "",
      plan_qty: 1,
      plan_subtotal: 0,
      plan_gst: 0,
      plan_gst_amount: 0,
      plan_grandtotal: 0,
      terms_conditions: "no",
      promotions_updates: "no",
      error_msg: "",
      stripe_loaded: 0,
      stripe_log: 0,
      qrcodeData: [],
      qrcode_str: "",
      qrCode: "",
      runingNum: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      updatedQty: false,
      startbuyVoucher: false,
      paymentMethod: "MSwipe",
      manualPayment: false,
      mswipePayment: false,
      reddotPayment: false,
      savecard: false,
      gSettings: "",
      selectedVoucher: "",
      signFrm: false,

      reg_phone_no: "",
      reg_phone_no_error: "",
      regotpdata: {
        reg_otp_dgt1: "",
        reg_otp_dgt2: "",
        reg_otp_dgt3: "",
        reg_otp_dgt4: "",
      },
      regotp_error: "",
      regotp_terms: "No",
      regotp_promo: "No",
      trigger_otp: "No",

      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      regcust_error: "",

      PayAmount: "",
      balanceAmount: 0,
      bonusAmount: 0,
      reciveAmount: 0,
      voucherBalance: 0,
      continueBill: false,
      regphone_error: "",
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
    this.submitRef = React.createRef();
    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSettings();
    this.getProductData();
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails }, () => {
        this.calculateAmt();
        if (this.state.continueBill === true) {
          this.setState({ continueBill: false }, () => {
            this.payBill();
          });
        }
      });
    }
  }

  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieID;
    axios
      .get(urlShringTxt, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          var manualPayment = result?.enable_manual_payment
            ? Boolean(result?.enable_manual_payment)
            : false;
          var paymentMethod = "";

          var mswipePayment = result?.enable_mswipe_payment
            ? Boolean(result?.enable_mswipe_payment)
            : false;

          var reddotPayment = result?.enable_reddot_payment
            ? Boolean(result?.enable_reddot_payment)
            : false;
          var reddotmv3ds = result?.enable_reddot_mv3ds
            ? Boolean(result?.enable_reddot_mv3ds)
            : false;
          if (paymentMethod === "" && reddotmv3ds === true) {
            paymentMethod = "mv3ds";
          }
          var reddotamex3ds = result?.enable_reddot_amex3ds
            ? Boolean(result?.enable_reddot_amex3ds)
            : false;
          if (paymentMethod === "" && reddotamex3ds === true) {
            paymentMethod = "amex3ds";
          }
          var reddotapplepay = result?.enable_reddot_applepay
            ? Boolean(result?.enable_reddot_applepay)
            : false;
          if (paymentMethod === "" && reddotapplepay === true) {
            paymentMethod = "applepay";
          }
          var reddotatome = result?.enable_reddot_atome
            ? Boolean(result?.enable_reddot_atome)
            : false;
          if (paymentMethod === "" && reddotatome === true) {
            paymentMethod = "atome";
          }
          var reddotgooglepay = result?.enable_reddot_googlepay
            ? Boolean(result?.enable_reddot_googlepay)
            : false;
          if (paymentMethod === "" && reddotgooglepay === true) {
            paymentMethod = "googlepay";
          }
          var reddotgrabpay = result?.enable_reddot_grabpay
            ? Boolean(result?.enable_reddot_grabpay)
            : false;
          if (paymentMethod === "" && reddotgrabpay === true) {
            paymentMethod = "grabpay";
          }
          var reddotpaynow = result?.enable_reddot_paynow
            ? Boolean(result?.enable_reddot_paynow)
            : false;
          if (paymentMethod === "" && reddotpaynow === true) {
            paymentMethod = "paynow";
          }

          if (paymentMethod === "" && mswipePayment === true) {
            paymentMethod = "MSwipe";
          }
          if (paymentMethod === "" && manualPayment === true) {
            paymentMethod = "QR Payment";
          }
          if (
            navigator.userAgent.indexOf("Mac") !== -1 &&
            reddotapplepay === true
          ) {
            paymentMethod = "applepay";
          }
          this.setState({
            gSettings: result,
            manualPayment: manualPayment,
            mswipePayment: mswipePayment,
            reddotPayment: reddotPayment,
            paymentMethod: paymentMethod,
            reddotmv3ds: reddotmv3ds,
            reddotamex3ds: reddotamex3ds,
            reddotapplepay: reddotapplepay,
            reddotatome: reddotatome,
            reddotgooglepay: reddotgooglepay,
            reddotgrabpay: reddotgrabpay,
            reddotpaynow: reddotpaynow,
            typeofCreditRedeem: result?.typeofCreditRedeem || 1,
            clientLogo: result?.company_logo,
          });
        }
      });
  }

  payBill() {
    var unquie_IDs = unquieID;
    let customer_id = this.state.UserId;
    let subtotal_amount = this.state.billPayAmount;
    let total_amount = this.state.plan_grandtotal;
    let tax_percentage = this.state.tax_percentage;

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    }

    if (isErrorMsg === "") {
      const loginType = cookie.load("loginType") || "";
      if (loginType === "Guest" && this.state.selectedVoucher !== "") {
        this.setState({ signFrm: true, continueBill: true });
      } else {
        showLoader("placeOredr");
        this.setState({ error_msg: "" });
        let subtotal_amount = 0;
        let productID = "";
        if (this.state.selectedVoucher !== "") {
          let voucher = this.state.voucherList[this.state.selectedVoucher];
          subtotal_amount = voucher.product_price;
          productID = voucher.product_id;
        } else {
          subtotal_amount = total_amount;
        }
        var postObject = {
          app_id: unquie_IDs,
          customer_id: this.state.UserId,
          subtotal_amount: subtotal_amount,
          total_amount: total_amount,
          tax_percentage: tax_percentage,
          terms_conditions: "yes",
          promotions_updates: "no",
          checkoutType: this.state.checkoutType,
          callbackurl: baseUrl,
          savecard: "N",
          outletID: cookie.load("outletID"),
          paymentMethod: this.state.paymentMethod,
          productID: productID,
          billPayAmount: this.state.billPayAmount,
          reciveAmount: this.state.reciveAmount,
          gstAmount: this.state.plan_gst_amount,
          directQRCode: cookie.load("DirectQRCode"),
        };
        console.log(postObject, "postObjectpostObject");
        //return false;
        //window.sessionStorage.setItem("postObject", qs.stringify(postObject));
        if (this.state.paymentMethod === "voucherpay") {
          this.comfirmRedeemFun();
        } else if (this.state.paymentMethod === "MSwipe") {
          var post_Object = {
            app_id: unquieID,
            qr_type: this.state.checkoutType,
            customer_id: this.state.UserId,
            payment_type: this.state.checkoutType,
            callbackurl: baseUrl + "placeorder",
            paid_amount: total_amount,
          };

          axios
            .post(
              apiUrl + "paymentV2/createPaymentLink",
              qs.stringify(post_Object),
              {
                headers: { Authorization: localStorage.getItem("token") },
              }
            )
            .then((res) => {
              hideLoader("placeOredr");
              if (res.data.status === "ok") {
                var productList = this.state.productList[0];
                localStorage.setItem(
                  "paymentRequestId",
                  res.data.payment_requestId
                );
                localStorage.setItem(
                  "voucherType",
                  productList.product_voucher
                );
                setTimeout(function () {
                  window.location.href = res.data.redirectURL;
                }, 500);
              }
            });
        } else {
          axios
            .post(
              apiUrl + "paymentV2/processReddotPayment",
              qs.stringify(postObject),
              {
                headers: { Authorization: localStorage.getItem("token") },
              }
            )
            .then((res) => {
              hideLoader("placeOredr");
              if (res.data.status === "ok") {
                localStorage.setItem(
                  "paymentRequestIdRedtod",
                  res.data.payment_requestId
                );
                window.location.href = res.data.redirectURL;
              } else {
                showAlert("Alert", res.data.message, "error");
              }
            });
        }
      }
    } else {
      this.setState({ error_msg: isErrorMsg });
    }
  }

  comfirmRedeemFun() {
    let userCredits = this.state.billPayAmount;
    if (parseFloat(userCredits) > 0) {
      var postObject = {
        app_id: unquieID,
        cust_qr_str: cookie.load("DirectQRCode"),
        user_outlet_id: cookie.load("outletID"),
        user_id: cookie.load("UserId"),
        transUserID: cookie.load("UserId"),
        user_credits: userCredits,
      };
      showLoader("placeOredr");
      axios
        .post(apiUrl + "customer/redeemCashQrcode", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("placeOredr");
          if (res.data.status === "ok") {
            Swal.fire({
              icon: "success",
              title: "Redeemed!",
              text: "Cash Voucher has been redeemed succuessfully.",
              customClass: {
                confirmButton: "btn btn-success waves-effect",
              },
            });
            this.props.history.push("/myaccount");
          } else {
            this.setState({ redeemqr_error: res.data.message });
          }
        });
    } else {
      this.setState({ redeemqr_error: "Invalide QR" });
    }
  }

  selectPaymentMethod(paymentMethod) {
    this.setState({ paymentMethod });
  }

  getProductData() {
    axios
      .get(
        `${apiUrl}products/products_list?app_id=${unquieID}&product_type=7&recomentedVoucher=Yes`,
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ voucherList: res.data.result_set });
        }
      });
  }
  handleFldChange(e) {
    const re = /^[0-9 \b]+$/;
    let name = e.target.name;
    if (
      e.target.name === "reg_otp_dgt1" ||
      e.target.name === "reg_otp_dgt2" ||
      e.target.name === "reg_otp_dgt3" ||
      e.target.name === "reg_otp_dgt4"
    ) {
      if (e.target.value === "" || re.test(e.target.value)) {
        var mblnumber = this.space(e.target.value);
        var mblnumberLenght = mblnumber.length;
        if (mblnumberLenght <= 1) {
          let field = e.target.name;
          let fieldvalue = e.target.value;
          this.setState(
            update(this.state, {
              regotpdata: { [field]: { $set: fieldvalue } },
            }),
            function () {
              if (field === "reg_otp_dgt1" && mblnumberLenght === 1) {
                $("#reg_otp_dgt2").focus();
              }
              if (field === "reg_otp_dgt2" && mblnumberLenght === 1) {
                $("#reg_otp_dgt3").focus();
              }
              if (field === "reg_otp_dgt3" && mblnumberLenght === 1) {
                $("#reg_otp_dgt4").focus();
              }
            }
          );
        }
      }
    } else {
      this.setState({ [name]: e.target.value, [`${name}_error`]: "" }, () => {
        this.calculateAmt();
      });
    }
  }
  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }
  calculateAmt() {
    let payAmount = this.state.billPayAmount || 0;
    let balanceAmount = "";
    let bonusAmount = "";
    let reciveAmount = "";
    if (this.state.selectedVoucher !== "") {
      let voucher = this.state.voucherList[this.state.selectedVoucher];
      payAmount = voucher.product_price;
      balanceAmount = payAmount - this.state.billPayAmount;
      if (this.state.typeofCreditRedeem === "2") {
        bonusAmount = voucher.product_voucher_bonus_amount;
      }
      reciveAmount = voucher.totalCridit;
    }
    let customerData = this.state.customerData || "";
    let voucherBalance = 0;
    if (customerData !== "") {
      if (customerData?.custmap_available_credits) {
        voucherBalance = customerData?.custmap_available_credits;
      }
    }
    let plan_grandtotal = parseFloat(payAmount);
    this.setState({
      plan_grandtotal: plan_grandtotal,
      PayAmount: payAmount,
      balanceAmount: balanceAmount,
      reciveAmount: reciveAmount,
      voucherBalance: voucherBalance,
      bonusAmount: bonusAmount,
    });
  }
  selectVoucher(selectedVoucher) {
    if (this.state.selectedVoucher === selectedVoucher) {
      this.setState({ selectedVoucher: "" }, () => {
        this.calculateAmt();
      });
    } else {
      this.setState({ selectedVoucher }, () => {
        this.calculateAmt();
      });
    }
  }

  goBackTo(refPage, event) {
    event.preventDefault();
    var regotpdata = {
      reg_otp_dgt1: "",
      reg_otp_dgt2: "",
      reg_otp_dgt3: "",
      reg_otp_dgt4: "",
    };
    this.setState({
      bottompopup: refPage,
      reg_phone_no_error: "",
      regphone_error: "",
      regotpdata: regotpdata,
      regotp_terms: "No",
      regotp_promo: "No",
      regotp_error: "",
      cust_name: "",
      cust_email_id: "",
      birthday_month: "",
      cust_ref_code: "",
      regcust_error: "",
    });
  }

  regSendOtp(typeflg, event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    if (regphoneNo !== "") {
      var postObject = {
        customer_mobile: regphoneNo,
        otp_type: typeflg,
      };
      let loadrdivcls = typeflg === "login" ? "login-otp-link" : "reg-otp-link";
      showLoaderLst(loadrdivcls, "class");
      axios
        .post(apiUrl + "customer/send_customer_otp", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst(loadrdivcls, "class");
          if (res.data.status === "ok") {
            if (
              typeflg === "login" ||
              (typeflg === "register" && res.data.is_existing_user === "yes")
            ) {
              let custArr = res.data.result;
              cookie.save("UserId", custArr.customer_id, { path: "/" });
              cookie.remove("loginType", { path: "/" });
              cookie.save("UserEmail", custArr.customer_email, { path: "/" });
              cookie.save(
                "UserFname",
                custArr.customer_first_name !== ""
                  ? custArr.customer_first_name
                  : "",
                { path: "/" }
              );
              cookie.save(
                "UserLname",
                custArr.customer_last_name !== ""
                  ? custArr.customer_last_name
                  : "",
                { path: "/" }
              );
              cookie.save("UserMobile", regphoneNo, { path: "/" });
              cookie.save("IsVerifiedUser", "No", { path: "/" });
              localStorage.setItem("token", custArr.token);
              if (unquieID !== "") {
                localStorage.setItem("allowCompanyRedirect", "Yes");
              }
              this.setState(
                {
                  signFrm: false,
                  UserId: custArr.customer_id,
                },
                () => {
                  this.calculateAmt();
                  this.props.getCustomerDetails(
                    "&customer_id=" + custArr.customer_id
                  );
                }
              );
            } else {
              this.setState({ bottompopup: "confirm" });
            }
          } else {
            this.setState({ reg_phone_no_error: res.data.message });
          }
        });
    } else {
      this.setState({ reg_phone_no_error: "Phone number required" });
    }
  }

  custRegistration(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var errorMgs = "";
    if (regphoneNo === "") {
      errorMgs = "Mobile No required";
    } else if (this.state.cust_name === "") {
      errorMgs = "Name required";
    } else if (this.state.cust_email_id === "") {
      errorMgs = "Email required";
    } else if (this.state.birthday_month === "") {
      errorMgs = "Birthday month required";
    }

    if (errorMgs === "") {
      var postObject = {
        app_id: unquieID,
        customer_phone: regphoneNo,
        customer_email: this.state.cust_email_id,
        customer_birthmonth: this.state.birthday_month,
        customer_ref_code: this.state.cust_ref_code,
        customer_name: this.state.cust_name,
      };
      showLoaderLst("cust-reg-btn", "class");
      axios
        .post(apiUrl + "customer/registration", qs.stringify(postObject))
        .then((res) => {
          hideLoaderLst("cust-reg-btn", "class");
          if (res.data.status === "ok") {
            let custArr = res.data.result_set;
            cookie.save("UserId", custArr.customer_id, { path: "/" });
            cookie.save("UserEmail", custArr.customer_email, { path: "/" });
            cookie.remove("loginType", { path: "/" });
            cookie.save(
              "UserFname",
              custArr.customer_first_name !== ""
                ? custArr.customer_first_name
                : "",
              { path: "/" }
            );
            cookie.save(
              "UserLname",
              custArr.customer_last_name !== ""
                ? custArr.customer_last_name
                : "",
              { path: "/" }
            );
            cookie.save("UserMobile", regphoneNo, { path: "/" });
            cookie.save("IsVerifiedUser", "Yes", { path: "/" });
            localStorage.setItem("token", custArr?.token);

            this.setState(
              {
                signFrm: false,
                UserId: custArr.customer_id,
              },
              () => {
                this.calculateAmt();
                this.props.getCustomerDetails(
                  "&customer_id=" + custArr.customer_id
                );
              }
            );
          } else {
            this.setState({ regcust_error: res.data.message });
          }
        });
    } else {
      this.setState({ regcust_error: errorMgs });
    }
  }

  confirmPhone(event) {
    event.preventDefault();
    this.setState(
      { bottompopup: "regotp", runingNum: 120 },
      function () {
        this.setRuningNum();
      }.bind(this)
    );
  }
  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    var downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }
  displayRunNumbar() {
    if (this.state.regotp_error !== "") {
      return <p className="error_info">{this.state.regotp_error}</p>;
    } else if (this.state.runingNum > 0) {
      return (
        <p className="runing_num">Resend in {this.state.runingNum} Seconds</p>
      );
    } else {
      return <p className="resend_link">Resend</p>;
    }
  }

  changeRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    if (field === "trams") {
      var regotpterms = regotp_terms === "Yes" ? "No" : "Yes";
      this.setState({ regotp_terms: regotpterms });
      if (regotp_terms === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }

    if (field === "promo") {
      var regotpPromo = regotp_promo === "Yes" ? "No" : "Yes";
      this.setState({ regotp_promo: regotpPromo });
      if (regotpPromo === "yes") {
        this.setState({ regotp_terms: "No" });
      }
    }
  }

  chakRegOtpChkBox(field) {
    var regotp_terms = this.state.regotp_terms;
    var regotp_promo = this.state.regotp_promo;
    var chkBox = "";
    if (field === "trams") {
      chkBox = regotp_terms === "Yes" ? true : false;
      return chkBox;
    } else if (field === "promo") {
      chkBox = regotp_promo === "Yes" ? true : false;
      return chkBox;
    }
  }
  cancelAct(event) {
    event.preventDefault();
    this.setState({ signFrm: false });
  }
  openLogin(e) {
    e.preventDefault();
    this.setState({ signFrm: true });
  }
  regVerifyOtp(event) {
    event.preventDefault();
    var regphoneNo = this.state.reg_phone_no;
    regphoneNo = regphoneNo.replace(/ /g, "");
    var regotpData = this.state.regotpdata;
    var regotpval =
      regotpData.reg_otp_dgt1 +
      regotpData.reg_otp_dgt2 +
      regotpData.reg_otp_dgt3 +
      regotpData.reg_otp_dgt4;
    regotpval = regotpval.replace(/ /g, "");
    var errorMgs = "";
    if (regotpval.length !== 4) {
      errorMgs = "Please enter the OTP";
    } else if (this.state.regotp_terms !== "Yes") {
      errorMgs = "Terms & Conditions required";
    } else if (this.state.regotp_promo !== "Yes") {
      errorMgs = "Promotions required";
    }

    if (regphoneNo !== "" && errorMgs === "") {
      var postObject = {
        app_id: unquieID,
        customer_mobile: regphoneNo,
        customer_otp_val: regotpval,
        otp_type: "register",
      };
      showLoaderLst("reg-otp-verify", "class");
      axios
        .post(
          apiUrl + "customer/customer_otp_verification",
          qs.stringify(postObject)
        )
        .then((res) => {
          hideLoaderLst("reg-otp-verify", "class");
          if (res.data.status === "ok") {
            this.setState({ bottompopup: "regpersonal", runingNum: 0 });
          } else {
            this.setState({ regotp_error: res.data.message });
          }
        });
    } else {
      this.setState({ regotp_error: errorMgs });
    }
  }
  render() {
    let planGstAmount = this.state.plan_gst_amount;
    let loginType = cookie.load("loginType") || "";
    if (this.state.gSettings !== "") {
      return (
        <div className="main-div direct-pay">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />

          <div className="mbtm-need rel">
            <div className="container">
              <div className="cart-detail">
                <div className="cart-detail-header">
                  {loginType === "Guest" && (
                    <div className="directpay-login">
                      Already Have An Account?{" "}
                      <a href="/" onClick={this.openLogin.bind(this)}>
                        Login With Mobile No
                      </a>
                    </div>
                  )}
                </div>
                <div className="cart-detail-body">
                  <div className="billpay">
                    {/* <h3>Bill details</h3> */}
                    <span>$</span>
                    <input
                      placeholder="Enter Amount To Pay"
                      value={this.state.billPayAmount}
                      autoComplete="off"
                      name="billPayAmount"
                      type="number"
                      step="0.01"
                      inputmode="decimal"
                      pattern="\d*"
                      onChange={this.handleFldChange.bind(this)}
                    />
                  </div>
                  {this.state.voucherList.length > 0 &&
                    this.state.billPayAmount > 0 && (
                      <div className="voucher-list">
                        <h2>Cash Vouchers</h2>
                        <div class="cards">
                          {this.state.voucherList.map((item, index) => {
                            var billPayAmount = this.state.billPayAmount;
                            if (billPayAmount <= item.totalCridit) {
                              return (
                                <div
                                  class={`card ${
                                    this.state.selectedVoucher === index
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={this.selectVoucher.bind(this, index)}
                                >
                                  <div class="offer-info">
                                    <strong>{item.discountPrecentage}%</strong>
                                  </div>
                                  <div class="amount">
                                    <span>Pay</span>
                                    <div class="amt-display">
                                      {showPriceSeparate(
                                        item.product_price,
                                        0,
                                        "no"
                                      )}
                                    </div>
                                  </div>
                                  <div class="voucher-plus">+</div>
                                  <div class="offer">
                                    {this.state.typeofCreditRedeem === "2"
                                      ? "Discount"
                                      : "Bonus"}
                                    <div class="amt-display">
                                      {this.state.typeofCreditRedeem === "2"
                                        ? item.discountPrecentage + "%"
                                        : showPriceSeparate(
                                            item.product_voucher_bonus_amount,
                                            0,
                                            "no"
                                          )}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                        </div>
                      </div>
                    )}

                  {/* this.state.voucherList.length > 0 &&
                    this.state.billPayAmount > 0 && (
                      <div className="home-buy-voucher">
                        <div className="voucher-section">
                          <h2>Cash Vouchers</h2>
                          <div className="vouchers">
                            {this.state.voucherList.map((item, index) => {
                              var billPayAmount = this.state.billPayAmount;
                              if (billPayAmount <= item.totalCridit) {
                                return (
                                  <div
                                    className={`voucher-card ${
                                      this.state.selectedVoucher === index
                                        ? "active"
                                        : ""
                                    }`}
                                    key={index}
                                  >
                                    <div className="vc-top">
                                      <span className="discount">
                                        <strong>
                                          {item.discountPrecentage}%
                                        </strong>{" "}
                                        {this.state.typeofCreditRedeem === "2"
                                          ? "Cashback"
                                          : "Bonus"}
                                      </span>

                                      <h3>
                                        <div className="recived">Receive</div>
                                        {showPriceSeparate(item.totalCridit, 0)}
                                      </h3>
                                    </div>
                                    <div className="vc-bottom">
                                      <p
                                        onClick={this.selectVoucher.bind(
                                          this,
                                          index
                                        )}
                                      >
                                        Buy for{" "}
                                        {showPriceValue(item.product_price)}
                                      </p>
                                    </div>
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    ) */}

                  <ul>
                    {this.state.selectedVoucher !== "" && (
                      <>
                        <li>
                          <span>You Pay</span>
                          <strong>
                            {showPriceSeparate(this.state.PayAmount)}
                          </strong>
                        </li>
                        {this.state.reciveAmount !== "" && (
                          <li>
                            <span>Receive</span>
                            <strong>
                              {showPriceSeparate(this.state.reciveAmount)}
                            </strong>
                          </li>
                        )}
                      </>
                    )}
                    <li>
                      <span>Bill</span>
                      <strong>
                        {showPriceSeparate(this.state.billPayAmount)}
                      </strong>
                    </li>
                    {parseFloat(planGstAmount) > 0 && (
                      <li>
                        <span>GST</span>
                        <strong>
                          <sup>$</sup>
                          {planGstAmount.toFixed(2)}
                        </strong>
                      </li>
                    )}
                    {this.state.balanceAmount !== "" && (
                      <li>
                        <span>Balance For Next Visit</span>
                        <strong>
                          {showPriceSeparate(this.state.balanceAmount)}
                        </strong>
                      </li>
                    )}

                    <li className="ts-total">
                      <span>Total</span>
                      <strong>
                        {showPriceSeparate(this.state.plan_grandtotal)}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="payment-container">
                <h2>Payment method</h2>
                {this.state.selectedVoucher === "" &&
                  parseFloat(this.state.voucherBalance) > 0 &&
                  cookie.load("loginType") !== "Guest" && (
                    <div
                      className={`payment-option ${
                        this.state.paymentMethod === "voucherpay"
                          ? "active"
                          : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(
                        this,
                        "voucherpay"
                      )}
                    >
                      <label className="checkbox-container">
                        <input
                          type="radio"
                          name="payment"
                          defaultChecked={
                            this.state.paymentMethod === "voucherpay"
                              ? true
                              : false
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="label-text">
                          Your Cash Vouchers
                          <br />
                          {this.state.voucherBalance}
                        </span>
                        <img
                          src={walletlight}
                          alt="Voucher Pay"
                          className="voucher-pay"
                        />
                      </label>
                    </div>
                  )}
                {this.state.reddotmv3ds === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "mv3ds" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "mv3ds")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "mv3ds" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">Master / Visa Card</span>
                      <img
                        src={mastercard}
                        alt="Master"
                        className="master-card"
                      />
                      <img src={visa} alt="Visa" className="visa-card" />
                    </label>
                  </div>
                )}
                {this.state.reddotapplepay === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "applepay" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "applepay")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "applepay" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">Apple Pay</span>
                      <img
                        src={applePay}
                        alt="ApplePay"
                        className="apple-icon"
                      />
                    </label>
                  </div>
                )}
                {this.state.reddotamex3ds === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "amex3ds" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "amex3ds")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "amex3ds" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">Amex Pay</span>
                      <img
                        src={amexPay}
                        alt="ApplePay"
                        className="amexpay-icon"
                      />
                    </label>
                  </div>
                )}
                {this.state.reddotgooglepay === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "googlepay" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "googlepay")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "googlepay"
                            ? true
                            : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">Google Pay</span>
                      <img
                        src={googlePay}
                        alt="ApplePay"
                        className="googlepay-icon"
                      />
                    </label>
                  </div>
                )}
                {this.state.reddotatome === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "atome" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "atome")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "atome" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">Atome Pay</span>
                      <img src={atome} alt="ApplePay" className="atome-icon" />
                    </label>
                  </div>
                )}
                {this.state.reddotpaynow === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "paynow" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "paynow")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "paynow" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">PayNow</span>
                      <img
                        src={paynow}
                        alt="ApplePay"
                        className="paynow-icon"
                      />
                    </label>
                  </div>
                )}
                {this.state.reddotgrabpay === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "grabpay" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "grabpay")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "grabpay" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">GrabPay</span>
                      <img
                        src={grabpay}
                        alt="ApplePay"
                        className="grabpay-icon"
                      />
                    </label>
                  </div>
                )}
                {this.state.mswipePayment === true && (
                  <div
                    className={`payment-option ${
                      this.state.paymentMethod === "MSwipe" ? "active" : ""
                    }`}
                    onClick={this.selectPaymentMethod.bind(this, "MSwipe")}
                  >
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="payment"
                        defaultChecked={
                          this.state.paymentMethod === "MSwipe" ? true : false
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="label-text">MSwipe</span>
                      <img
                        src={mswipe}
                        alt="ApplePay"
                        className="mswipe-icon"
                      />
                    </label>
                  </div>
                )}
              </div>

              {/*  <div className="payment-method">
                <ul>
                  {this.state.selectedVoucher === "" &&
                    parseFloat(this.state.voucherBalance) > 0 &&
                    cookie.load("loginType") != "Guest" && (
                      <li
                        className={`${
                          this.state.paymentMethod === "voucherpay"
                            ? "active"
                            : ""
                        }`}
                        onClick={this.selectPaymentMethod.bind(
                          this,
                          "voucherpay"
                        )}
                      >
                        <div className="pay-image">
                          <img
                            src={walletlight}
                            alt="Voucher Pay"
                            className="voucher-pay"
                          />
                        </div>
                        <div>
                          Your Cash Vouchers
                          <br />
                          {this.state.voucherBalance}
                        </div>
                      </li>
                    )}
                  {this.state.reddotmv3ds === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "mv3ds" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "mv3ds")}
                    >
                      <div className="pay-image master-visa">
                        <img
                          src={mastercard}
                          alt="Master"
                          className="master-card"
                        />
                        <img src={visa} alt="Visa" className="visa-card" />
                      </div>
                      <div>Master / Visa Card</div>
                    </li>
                  )}
                  {this.state.reddotamex3ds === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "amex3ds" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "amex3ds")}
                    >
                      <div className="pay-image">
                        <img src={amexPay} alt="Amex Pay" />
                      </div>
                      <div>Amex Pay</div>
                    </li>
                  )}
                  {this.state.reddotgooglepay === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "googlepay" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "googlepay")}
                    >
                      <div className="pay-image">
                        <img src={googlePay} alt="Google Pay" />
                      </div>
                      <div>Google Pay</div>
                    </li>
                  )}
                  {this.state.reddotapplepay === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "applepay" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "applepay")}
                    >
                      <div className="pay-image">
                        <img
                          src={applePay}
                          alt="ApplePay"
                          className="apple-pay"
                        />
                      </div>
                      <div>ApplePay</div>
                    </li>
                  )}
                  {this.state.reddotatome === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "atome" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "atome")}
                    >
                      <div className="pay-image">
                        <img
                          src={atome}
                          alt="Atome Pay"
                          className="attom-pay"
                        />
                      </div>
                      <div>Atome Pay</div>
                    </li>
                  )}
                  {this.state.reddotpaynow === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "paynow" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "paynow")}
                    >
                      <div className="pay-image">
                        <img src={paynow} alt="PayNow" className="pay-img" />
                      </div>
                      <div>PayNow</div>
                    </li>
                  )}
                  {this.state.reddotgrabpay === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "grabpay" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "grabpay")}
                    >
                      <div className="pay-image">
                        <img src={grabpay} alt="GrabPay" className="pay-img" />
                      </div>
                      <div>GrabPay</div>
                    </li>
                  )}

                  {this.state.mswipePayment === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "MSwipe" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "MSwipe")}
                    >
                      <div className="pay-image">
                        <img src={mswipe} alt="MSwipe" className="mswipe-pay" />
                      </div>
                      <div>MSwipe</div>
                    </li>
                  )}
                </ul>
              </div> */}
            </div>
          </div>
          <IonFooter collapse="fade">
            <div className="sticky-single-btn">
              <a
                href={() => false}
                className="button green-btn place-order-link"
                id="placeOredr"
                onClick={this.payBill.bind(this)}
              >
                Pay with{" "}
                <span>
                  {" "}
                  {(() => {
                    if (this.state.paymentMethod === "mv3ds") {
                      return (
                        <>
                          <img
                            src={mastercard}
                            alt="Master"
                            className="master-card"
                          />
                          <img src={visa} alt="Visa" className="visa-card" />
                        </>
                      );
                    } else if (this.state.paymentMethod === "applepay") {
                      return (
                        <img
                          src={applePay}
                          alt="ApplePay"
                          className="apple-icon"
                        />
                      );
                    } else if (this.state.paymentMethod === "amex3ds") {
                      return (
                        <img
                          src={amexPay}
                          alt="ApplePay"
                          className="amexpay-icon"
                        />
                      );
                    } else if (this.state.paymentMethod === "googlepay") {
                      return (
                        <img
                          src={googlePay}
                          alt="ApplePay"
                          className="googlepay-icon"
                        />
                      );
                    } else if (this.state.paymentMethod === "atome") {
                      return (
                        <img
                          src={atome}
                          alt="ApplePay"
                          className="atome-icon"
                        />
                      );
                    } else if (this.state.paymentMethod === "paynow") {
                      return (
                        <img
                          src={paynow}
                          alt="ApplePay"
                          className="paynow-icon"
                        />
                      );
                    } else if (this.state.paymentMethod === "grabpay") {
                      return (
                        <img
                          src={grabpay}
                          alt="ApplePay"
                          className="grabpay-icon"
                        />
                      );
                    } else if (this.state.paymentMethod === "MSwipe") {
                      return (
                        <img
                          src={mswipe}
                          alt="ApplePay"
                          className="mswipe-icon"
                        />
                      );
                    }
                  })()}
                  <label>Pay</label>
                </span>
              </a>
              <div>
                <span className="error">{this.state.error_msg}</span>
              </div>
            </div>
          </IonFooter>

          <BottomSheet
            open={this.state.signFrm}
            onDismiss={() => {
              this.setState({ signFrm: false });
            }}
          >
            <div className="container">
              {this.state.bottompopup === "regphone" && (
                <div className="welcome-phone">
                  <div className="wp-top">
                    <h2>Welcome to EpicPay</h2>
                    <p>Enter your phone number to create your account</p>
                  </div>
                  <div className="input-phone">
                    <div className="prefix-merge">
                      <div className="prefix">+65</div>
                      <div className="nxt-fix">
                        <input
                          type="input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="reg_phone_no"
                          value={this.state.reg_phone_no}
                          placeholder="Phone number"
                          onChange={this.handleFldChange.bind(this)}
                        />
                      </div>
                    </div>
                    {this.state.reg_phone_no_error !== "" && (
                      <span className="error-info">
                        {this.state.reg_phone_no_error}
                      </span>
                    )}
                    <p>
                      We will send you a one-time SMS, Additional carriers
                      charges may apply
                    </p>
                  </div>
                  <div className="bottom-btn bb-txt textcenter">
                    <a
                      href="/"
                      className="button full-btn reg-otp-link"
                      onClick={this.regSendOtp.bind(this, "register")}
                    >
                      Send OTP
                    </a>
                    <p>
                      Do you have an account ?{" "}
                      <a
                        href="/"
                        onClick={this.goBackTo.bind(this, "loginfrm")}
                      >
                        Login here
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "confirm" && (
                <div className="update-personal">
                  <div className="up-header confirm-header textcenter">
                    <h2>
                      You have entered<br></br>+65 {this.state.reg_phone_no}
                    </h2>
                    <img src={cellphone1} alt="phone" />
                  </div>
                  <div className="up-form confirm-phone">
                    <p>
                      A one-time verification code will be sent to this mobile
                      number
                    </p>
                    <p>
                      Press ‘Confirm’ to proceed or ‘Edit’ to amend your
                      details.
                    </p>
                    {this.state.regcust_error !== "" && (
                      <p className="error_info">{this.state.regcust_error}</p>
                    )}
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this, "regphone")}
                      >
                        Edit
                      </a>
                      <a
                        href="/"
                        className="button cust-reg-btn"
                        onClick={this.confirmPhone.bind(this)}
                      >
                        Confirm
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "regotp" && (
                <div className="enter-otp">
                  <h2>Enter OTP</h2>
                  <div className="four-bx-col">
                    <div className="four-bx">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt1"
                        id="reg_otp_dgt1"
                        value={this.state.regotpdata.reg_otp_dgt1}
                        onChange={this.handleFldChange.bind(this)}
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt2"
                        id="reg_otp_dgt2"
                        value={this.state.regotpdata.reg_otp_dgt2}
                        onChange={this.handleFldChange.bind(this)}
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt3"
                        id="reg_otp_dgt3"
                        value={this.state.regotpdata.reg_otp_dgt3}
                        onChange={this.handleFldChange.bind(this)}
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt4"
                        id="reg_otp_dgt4"
                        value={this.state.regotpdata.reg_otp_dgt4}
                        onChange={this.handleFldChange.bind(this)}
                      />
                    </div>
                    {this.displayRunNumbar()}
                  </div>
                  {this.state.trigger_otp !== "Yes" && (
                    <div className="otp-checkbox">
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            name="regotp_terms"
                            onChange={this.changeRegOtpChkBox.bind(
                              this,
                              "trams"
                            )}
                            checked={this.chakRegOtpChkBox("trams")}
                          />
                          <p>
                            I confirm I have read and accept the{" "}
                            <a href="/">Terms & Conditions</a>,{" "}
                            <a href="/">Privacy Policy</a> and the{" "}
                            <a href="/">PDPA consent</a>.
                          </p>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            name="regotp_promo"
                            onChange={this.changeRegOtpChkBox.bind(
                              this,
                              "promo"
                            )}
                            checked={this.chakRegOtpChkBox("promo")}
                          />
                          <p>
                            I'd like to receive news, updates and promotions
                            from Buzzr via email and sms
                          </p>
                        </li>
                      </ul>
                    </div>
                  )}
                  {this.state.trigger_otp === "Yes" ? (
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.cancelAct.bind(this)}
                      >
                        Cancel
                      </a>
                      <a
                        href="/"
                        className="button cust-reg-btn"
                        onClick={this.tgrVerifyOtp.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  ) : (
                    <div className="bottom-btn textcenter">
                      <a
                        href="/"
                        className="button full-btn reg-otp-verify"
                        onClick={this.regVerifyOtp.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  )}
                </div>
              )}

              {this.state.bottompopup === "regpersonal" && (
                <div className="update-personal">
                  <div className="up-header textcenter">
                    <p>Update your personal details</p>
                  </div>
                  <div className="up-form">
                    <input
                      type="text"
                      placeholder="Name"
                      name="cust_name"
                      value={this.state.cust_name}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="cust_email_id"
                      value={this.state.cust_email_id}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    <select
                      name="birthday_month"
                      value={this.state.birthday_month}
                      className="components_selct components_selctbox_cls"
                      onChange={this.handleFldChange.bind(this)}
                    >
                      <option value="">Birthday Month</option>
                      <option value="january">January</option>
                      <option value="february">February</option>
                      <option value="march">March</option>
                      <option value="april">April</option>
                      <option value="may">May</option>
                      <option value="june">June</option>
                      <option value="july">July</option>
                      <option value="august">August</option>
                      <option value="september">September</option>
                      <option value="october">October</option>
                      <option value="november">November</option>
                      <option value="december">December</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Referral Code"
                      name="cust_ref_code"
                      value={this.state.cust_ref_code}
                      onChange={this.handleFldChange.bind(this)}
                    />
                    {this.state.regcust_error !== "" && (
                      <p className="error_info">{this.state.regcust_error}</p>
                    )}
                    <div className="up-frm-btn">
                      <a
                        href="/"
                        className="button ghost-btn"
                        onClick={this.goBackTo.bind(this, "regphone")}
                      >
                        Cancel
                      </a>
                      <a
                        href="/"
                        className="button cust-reg-btn"
                        onClick={this.custRegistration.bind(this)}
                      >
                        Register
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "loginfrm" && (
                <div className="welcome-phone login-frm">
                  <div className="wp-top">
                    <h2>Login to EpicPay</h2>
                    <p>Enter your phone number to and login to your account</p>
                  </div>
                  <div className="input-phone">
                    <div className="prefix-merge">
                      <div className="prefix">+65</div>
                      <div className="nxt-fix">
                        <input
                          type="input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="reg_phone_no"
                          value={this.state.reg_phone_no}
                          placeholder="Phone number"
                          /* onChange={this.handleFldChange.bind(this)} */
                        />
                      </div>
                    </div>
                    {this.state.regphone_error !== "" && (
                      <span className="error-info">
                        {this.state.regphone_error}
                      </span>
                    )}
                    <p>
                      We will send you a one-time SMS, Additional carriers
                      charges may apply
                    </p>
                  </div>
                  <div className="bottom-btn bb-txt textcenter">
                    <a
                      href="/"
                      className="button full-btn login-otp-link"
                      /* onClick={this.regSendOtp.bind(this, "login")} */
                    >
                      Login
                    </a>
                    <p>
                      Do not have an account ?{" "}
                      <a
                        href="/"
                        /* onClick={this.goBackTo.bind(this, "regphone")} */
                      >
                        Register here
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {this.state.bottompopup === "loginotp" && (
                <div className="enter-otp enter-otp-login">
                  <h2>Enter OTP</h2>
                  <div className="four-bx-col">
                    <div className="four-bx">
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt1"
                        id="reg_otp_dgt1"
                        value={this.state.regotpdata.reg_otp_dgt1}
                        /* onChange={this.handleFldChange.bind(this)} */
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt2"
                        id="reg_otp_dgt2"
                        value={this.state.regotpdata.reg_otp_dgt2}
                        /* onChange={this.handleFldChange.bind(this)} */
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt3"
                        id="reg_otp_dgt3"
                        value={this.state.regotpdata.reg_otp_dgt3}
                        /* onChange={this.handleFldChange.bind(this)} */
                      />
                      <input
                        type="input"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="reg_otp_dgt4"
                        id="reg_otp_dgt4"
                        value={this.state.regotpdata.reg_otp_dgt4}
                        /*  onChange={this.handleFldChange.bind(this)} */
                      />
                    </div>
                    {/* this.displayRunNumbar() */}
                  </div>
                  <div className="bottom-btn">
                    <a
                      href="/"
                      className="button full-btn"
                      /* onClick={this.loginVerifyOtp.bind(this)} */
                    >
                      Login
                    </a>
                  </div>
                </div>
              )}
            </div>
          </BottomSheet>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div className="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  var productlistArr = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
    productlist: productlistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
