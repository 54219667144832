import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import cookie from "react-cookies";
import Swal from "sweetalert2";
import { GET_CUSTOMER_DETAILS, GET_PRODUCT_LIST } from "../../actions";
import { apiUrl, unquieID, headerconfig, baseUrl } from "../Settings/Config";
import {
  encodeValue,
  hideLoader,
  showAlert,
  showLoader,
  stripslashes,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import voucyellow from "../../common/images/voucher-yellow.svg";
import plus from "../../common/images/plus-sign.svg";
import minus from "../../common/images/minus-sign.svg";
import reloadQr from "../../common/images/reload_qr.png";
import googlePay from "../../common/images/payment/google-pay.svg";
import amexPay from "../../common/images/payment/american-express.svg";
import applePay from "../../common/images/payment/apple-pay.svg";
import atome from "../../common/images/payment/atome.svg";
import paynow from "../../common/images/payment/PayNowLogo.svg";
import grabpay from "../../common/images/payment/GrabPay.svg";
import mastercard from "../../common/images/payment/mastercard.svg";
import visa from "../../common/images/payment/visa.svg";
import qrcodepay from "../../common/images/payment/qr-code-pay.svg";
import mswipe from "../../common/images/payment/mswipe.svg";

var qs = require("qs");
var downloadTimer = "";
class Checkout extends Component {
  constructor(props) {
    super(props);
    var voucherSlug = this.props.location.state?.voucherSlug || "";
    var quantity = this.props.location.state?.quantity || 1;
    var checkoutType = this.props.location.state?.checkoutType || "topup";

    var planData =
      localStorage.getItem("planData") === null
        ? ""
        : localStorage.getItem("planData");
    planData = planData !== "" ? JSON.parse(planData) : [];
    var UserId = cookie.load("UserId") || "";
    this.state = {
      voucherSlug: voucherSlug,
      checkoutType: checkoutType,
      current_page: checkoutType === "topup" ? "Checkout" : "Checkout Voucher",
      backLink:
        checkoutType === "topup" ? "myaccount" : "buy-vouchers/" + voucherSlug,
      UserId: UserId,
      customerData: [],
      plan_data: planData,
      plan_qty: quantity,
      plan_subtotal: 0,
      plan_gst: 0,
      plan_gst_amount: 0,
      plan_grandtotal: 0,
      terms_conditions: "no",
      promotions_updates: "no",
      error_msg: "",
      stripe_loaded: 0,
      stripe_log: 0,
      qrcodeData: [],
      qrcode_str: "",
      qrCode: "",
      runingNum: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      updatedQty: false,
      startbuyVoucher: false,
      paymentMethod: "MSwipe",
      manualPayment: false,
      mswipePayment: false,
      reddotPayment: false,
      savecard: false,
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
    if (checkoutType === "topup") {
      if (Object.keys(planData).length > 0) {
      } else {
        props.history.push("/topup");
      }
    } else if (checkoutType === "buyvoucher") {
      if (voucherSlug === "") {
        props.history.push("/buy-vouchers");
      }
    }
    this.submitRef = React.createRef();

    if (cookie.load("IsVerifiedUser") !== "Yes") {
      cookie.save("triggerOTP", "Yes", { path: "/" });
      cookie.save("triggerFrom", "checkout", { path: "/" });
      if (checkoutType === "topup") {
        props.history.push("/");
      } else if (checkoutType === "buyvoucher") {
        var details = {
          voucherSlug: voucherSlug,
          quantity: quantity,
          checkoutType: checkoutType,
          triggerFrom: "/checkout",
        };
        this.props.history.push({
          pathname: "/",
          state: details,
        });
      }
    }

    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    if (this.state.checkoutType === "topup") {
      this.loadPlanData();
    }
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails }, () => {
        var custDetails = Object.keys(PropsDt.customerdetails);
        if (
          this.state.checkoutType === "buyvoucher" &&
          custDetails.length > 0
        ) {
          this.props.getProductList(
            "product_type=7&product_slug=" + this.state.voucherSlug
          );
        }
      });
    }
    if (this.state.productList !== PropsDt.productlist) {
      this.setState({ productList: PropsDt.productlist }, () => {
        this.loadProduct();
        if (this.state.checkoutType === "buyvoucher") {
          this.loadSettings();
        }
      });
    }
  }

  loadSettings() {
    var unquieIDs = unquieID;
    if (this.state.checkoutType === "buyvoucher") {
      let productList = this.state.productList[0];
      unquieIDs = productList.product_company_unique_id;
    }
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieIDs;
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;
        var manualPayment = result?.enable_manual_payment
          ? Boolean(result?.enable_manual_payment)
          : false;
        var paymentMethod = "";

        var mswipePayment = result?.enable_mswipe_payment
          ? Boolean(result?.enable_mswipe_payment)
          : false;

        var reddotPayment = result?.enable_reddot_payment
          ? Boolean(result?.enable_reddot_payment)
          : false;
        var reddotmv3ds = result?.enable_reddot_mv3ds
          ? Boolean(result?.enable_reddot_mv3ds)
          : false;
        if (paymentMethod === "" && reddotmv3ds === true) {
          paymentMethod = "mv3ds";
        }
        var reddotamex3ds = result?.enable_reddot_amex3ds
          ? Boolean(result?.enable_reddot_amex3ds)
          : false;
        if (paymentMethod === "" && reddotamex3ds === true) {
          paymentMethod = "amex3ds";
        }
        var reddotapplepay = result?.enable_reddot_applepay
          ? Boolean(result?.enable_reddot_applepay)
          : false;
        if (paymentMethod === "" && reddotapplepay === true) {
          paymentMethod = "applepay";
        }
        var reddotatome = result?.enable_reddot_atome
          ? Boolean(result?.enable_reddot_atome)
          : false;
        if (paymentMethod === "" && reddotatome === true) {
          paymentMethod = "applepay";
        }
        var reddotgooglepay = result?.enable_reddot_googlepay
          ? Boolean(result?.enable_reddot_googlepay)
          : false;
        if (paymentMethod === "" && reddotgooglepay === true) {
          paymentMethod = "googlepay";
        }
        var reddotgrabpay = result?.enable_reddot_grabpay
          ? Boolean(result?.enable_reddot_grabpay)
          : false;
        if (paymentMethod === "" && reddotgrabpay === true) {
          paymentMethod = "grabpay";
        }
        var reddotpaynow = result?.enable_reddot_paynow
          ? Boolean(result?.enable_reddot_paynow)
          : false;
        if (paymentMethod === "" && reddotpaynow === true) {
          paymentMethod = "paynow";
        }

        if (paymentMethod === "" && mswipePayment === true) {
          paymentMethod = "MSwipe";
        }
        if (paymentMethod === "" && manualPayment === true) {
          paymentMethod = "QR Payment";
        }

        this.setState({
          manualPayment: manualPayment,
          mswipePayment: mswipePayment,
          reddotPayment: reddotPayment,
          paymentMethod: paymentMethod,
          reddotmv3ds: reddotmv3ds,
          reddotamex3ds: reddotamex3ds,
          reddotapplepay: reddotapplepay,
          reddotatome: reddotatome,
          reddotgooglepay: reddotgooglepay,
          reddotgrabpay: reddotgrabpay,
          reddotpaynow: reddotpaynow,
        });
      }
    });
  }

  loadProduct(loadType = "") {
    if (this.state.productList.length > 0) {
      var productList = this.state.productList[0];
      var planGst = this.state.tax_percentage;
      var planSubtotal =
        parseFloat(productList.product_price) * parseInt(this.state.plan_qty);
      var planGstAmount =
        parseFloat(this.state.tax_percentage) > 0
          ? (planGst / 100) * planSubtotal
          : 0;
      var planGrandtotal = planSubtotal + planGstAmount;
      this.setState({
        plan_subtotal: planSubtotal,
        tax_percentage: planGst,
        plan_gst_amount: planGstAmount,
        plan_grandtotal: planGrandtotal,
        productName:
          productList.product_alias !== "" && productList.product_alias !== null
            ? stripslashes(productList.product_alias)
            : stripslashes(productList.product_name),
        error_msg: "",
      });
    }
  }

  loadPlanData() {
    let planData = this.state.plan_data;
    let planQty = this.state.plan_qty;
    let planSubtotal = this.state.plan_subtotal;
    //let planGst = this.state.plan_gst;
    let planGst = this.state.tax_percentage;
    let planGstAmount = this.state.plan_gst_amount;
    let planGrandtotal = this.state.plan_grandtotal;
    if (Object.keys(planData).length > 0) {
      if (parseInt(planQty) > 0) {
        planGst = planData.tax_percentage;
        let topupplanCreditsAmount = planData.topupplan_credits_amount;
        topupplanCreditsAmount =
          topupplanCreditsAmount !== ""
            ? parseFloat(topupplanCreditsAmount)
            : 0;
        if (topupplanCreditsAmount > 0) {
          planSubtotal = topupplanCreditsAmount * parseInt(planQty);
          planGstAmount = (planGst / 100) * planSubtotal;
          planGrandtotal = planSubtotal + planGstAmount;
          this.setState({
            plan_subtotal: planSubtotal,
            tax_percentage: planGst,
            plan_gst_amount: planGstAmount,
            plan_grandtotal: planGrandtotal,
            error_msg: "",
          });
          if (this.state.checkoutType === "topup") {
            this.loadSettings();
          }
        } else {
          this.props.history.push("/topup");
        }
      } else {
        localStorage.removeItem("planData");
        this.props.history.push("/topup");
      }
    } else {
      this.props.history.push("/topup");
    }
  }

  plnQtyAction(actionFlg) {
    let planQty = this.state.plan_qty;
    planQty = parseInt(planQty);
    if (actionFlg === "decr") {
      planQty = planQty > 0 ? planQty - 1 : planQty;
    } else {
      planQty = planQty + 1;
    }
    this.setState({ plan_qty: planQty, error_msg: "" }, () => {
      if (this.state.checkoutType === "topup") {
        this.loadPlanData();
      } else if (this.state.checkoutType === "buyvoucher") {
        this.setState({ updatedQty: true }, () => {
          this.loadProduct("updqty");
        });
      }
    });
  }
  rvmQtyAction(event) {
    event.preventDefault();
    let planQty = 0;
    this.setState({ plan_qty: planQty, error_msg: "" }, () => {
      if (this.state.checkoutType === "topup") {
        this.loadPlanData();
      } else if (this.state.checkoutType === "buyvoucher") {
        this.props.history.push("/buy-vouchers");
      }
    });
  }

  checkboxInfoFld(fieldNm) {
    let terms_conditions = this.state.terms_conditions;
    let promotions_updates = this.state.promotions_updates;
    if (fieldNm === "terms") {
      terms_conditions = terms_conditions === "yes" ? "no" : "yes";
      this.setState({ terms_conditions: terms_conditions, error_msg: "" });
    }
    if (fieldNm === "promotions") {
      promotions_updates = promotions_updates === "yes" ? "no" : "yes";
      this.setState({ promotions_updates: promotions_updates, error_msg: "" });
    }
    if (fieldNm === "savecard") {
      this.setState({ savecard: !this.state.savecard });
    }
  }

  proceedPayment() {
    if (this.state.checkoutType === "topup") {
      this.placeOrderValidation();
    } else if (this.state.checkoutType === "buyvoucher") {
      this.buyVoucher();
    }
  }

  placeOrderValidation() {
    let plan_data = this.state.plan_data;
    let customer_id = this.state.UserId;
    let plan_id = encodeValue(plan_data.topupplan_id);
    let plan_qty = this.state.plan_qty;
    let subtotal_amount = this.state.plan_subtotal;
    let total_amount = this.state.plan_grandtotal;
    let tax_percentage = this.state.tax_percentage;
    let terms_conditions = this.state.terms_conditions;
    let promotions_updates = this.state.promotions_updates;

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (plan_id === "") {
      isErrorMsg = "Plan Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (terms_conditions === "no") {
      isErrorMsg = "Please accept terms & conditions";
    }

    if (isErrorMsg === "") {
      showLoader("placeOredr");
      this.setState({ error_msg: "" });
      var postObject = {
        app_id: unquieID,
        customer_id: customer_id,
        plan_id: plan_id,
        plan_qty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        checkoutType: this.state.checkoutType,
        callbackurl: baseUrl,
        savecard: this.state.savecard === true ? "Y" : "N",
      };
      window.sessionStorage.setItem("postObject", qs.stringify(postObject));
      if (this.state.paymentMethod === "Reddot") {
        axios
          .post(
            apiUrl + "paymentV2/processReddotPayment",
            qs.stringify(postObject),
            headerconfig
          )
          .then((res) => {
            hideLoader("placeOredr");
            if (res.data.status === "ok") {
              localStorage.setItem(
                "paymentRequestIdRedtod",
                res.data.payment_requestId
              );
              window.location.href = res.data.redirectURL;
            } else {
              showAlert("Alert", res.data.message, "error");
            }
          });
      } else if (this.state.paymentMethod === "MSwipe") {
        var postObjects = {
          app_id: unquieID,
          qr_type: this.state.checkoutType,
          customer_id: this.state.UserId,
          payment_type: this.state.checkoutType,
          callbackurl: baseUrl + "placeorder",
          paid_amount: total_amount,
        };

        axios
          .post(
            apiUrl + "paymentV2/createPaymentLink",
            qs.stringify(postObjects),
            headerconfig
          )
          .then((res) => {
            hideLoader("placeOredr");
            if (res.data.status === "ok") {
              localStorage.setItem(
                "paymentRequestId",
                res.data.payment_requestId
              );
              window.location.href = res.data.redirectURL;
            }
          });
      }
    } else {
      this.setState({ error_msg: isErrorMsg });
    }
  }

  generateCustQrcode(planID, unquie_ID = "") {
    var postObject = {};
    postObject = {
      app_id: unquie_ID !== "" ? unquie_ID : unquieID,
      qr_type: this.state.checkoutType,
      customer_id: this.state.UserId,
    };
    if (this.state.checkoutType === "buyvoucher") {
      postObject["voucher_id"] = planID;
    } else {
      postObject["planID"] = planID;
    }
    axios
      .post(
        apiUrl + "customer/generateCustQrcode",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          let qrData =
            res.data.common.image_source +
            "/" +
            res.data.result_set.cust_qr_image;
          this.setState(
            {
              qrcodeData: res.data.result_set,
              qrcode_str: qrData,
              qrCode: res.data.result_set.cust_qr_code,
              runingNum: 25,
            },
            function () {
              this.setRuningNum();
              /*  if (this.state.inititalLoad === true) {
                this.setState({ inititalLoad: false }, () => { */
              this.checkingQRstatus();
              /*   });
              } */
            }.bind(this)
          );
        }
      });
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum });
    }, 1000);
  }

  reloadQrFun(event) {
    event.preventDefault();
    if (this.state.checkoutType === "buyvoucher") {
      let productList = this.state.productList[0];
      this.generateCustQrcode(
        productList.product_primary_id,
        productList.product_company_unique_id
      );
    } else {
      this.generateCustQrcode(this.state.plan_data.topupplan_id);
    }
  }

  checkingQRstatus() {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      qrcode: this.state.qrCode,
      customer_id: this.state.UserId,
    };
    axios
      .post(
        apiUrl + "customer/checkRedeemStatus",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        var currentThis = this;
        if (res.data.status === "ok") {
          if (this.state.runingNum > 0) {
            setTimeout(function () {
              currentThis.checkingQRstatus();
            }, 3000);
          }
        } else if (res.data.status === "used") {
          this.buyVoucher();
        } else {
        }
      });
  }

  placeOrder() {
    let plan_data = this.state.plan_data;
    let customer_id = this.state.UserId;
    let plan_id = encodeValue(plan_data.topupplan_id);
    let plan_qty = this.state.plan_qty;
    let subtotal_amount = this.state.plan_subtotal;
    let total_amount = this.state.plan_grandtotal;
    /* let tax_percentage = this.state.tax_percentage; */
    let terms_conditions = this.state.terms_conditions;
    /*  let promotions_updates = this.state.promotions_updates; */

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (plan_id === "") {
      isErrorMsg = "Plan Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (terms_conditions === "no") {
      isErrorMsg = "Please accept terms & conditions";
    }

    if (isErrorMsg === "") {
      this.setState({ error_msg: "" });
      /* var postObject = {
        app_id: unquieID,
        customer_id: this.state.UserId,
        plan_id: plan_id,
        plan_qty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        qrcode: this.state.qrCode,
        checkoutType: this.state.checkoutType,
      }; */
    }
  }

  buyVoucher() {
    var productID = "";
    var unquie_IDs = unquieID;
    if (this.state.checkoutType === "buyvoucher") {
      let productList = this.state.productList[0];
      productID = encodeValue(productList.product_id);
      unquie_IDs = productList.product_company_unique_id;
    } else {
      productID = encodeValue(this.state.plan_data.topupplan_id);
    }
    let customer_id = this.state.UserId;
    let plan_qty = this.state.plan_qty;
    let subtotal_amount = this.state.plan_subtotal;
    let total_amount = this.state.plan_grandtotal;
    let tax_percentage = this.state.tax_percentage;
    let terms_conditions = this.state.terms_conditions;
    let promotions_updates = this.state.promotions_updates;

    let isErrorMsg = "";
    if (customer_id === "") {
      isErrorMsg = "Customer Id was empty";
    } else if (productID === "") {
      isErrorMsg = "Product Id was empty";
    } else if (plan_qty === 0 || plan_qty === "") {
      isErrorMsg = "Plan Quantity was empty";
    } else if (subtotal_amount === 0 || subtotal_amount === "") {
      isErrorMsg = "Subtotal amount was empty";
    } else if (total_amount === 0 || total_amount === "") {
      isErrorMsg = "Total amount was empty";
    } else if (terms_conditions === "no") {
      isErrorMsg = "Please accept terms & conditions";
    }

    let influencerID = localStorage.getItem("influencerID") || "";

    if (isErrorMsg === "") {
      showLoader("placeOredr");
      this.setState({ error_msg: "" });
      var postObject = {
        app_id: unquie_IDs,
        customer_id: this.state.UserId,
        plan_qty: plan_qty,
        subtotal_amount: subtotal_amount,
        total_amount: total_amount,
        tax_percentage: tax_percentage,
        terms_conditions: terms_conditions,
        promotions_updates: promotions_updates,
        qrcode: this.state.qrCode,
        checkoutType: this.state.checkoutType,
        influencerID: influencerID,
        influencerBuyNow: localStorage.getItem("influencerBuyNow") || "",
        shareProductID: localStorage.getItem("shareProductID") || "",
        analyticsType: localStorage.getItem("analyticsType") || "",
        callbackurl: baseUrl,
        savecard: this.state.savecard === true ? "Y" : "N",
        paymentMethod: this.state.paymentMethod,
      };
      if (this.state.checkoutType === "buyvoucher") {
        postObject["productID"] = productID;
      } else {
        postObject["plan_id"] = productID;
      }
      if (this.state.paymentMethod === "QR Payment") {
        axios
          .post(
            apiUrl + "wallettopup/topup",
            qs.stringify(postObject),
            headerconfig
          )
          .then((res) => {
            if (res.data.status === "ok") {
              localStorage.removeItem("planData");
              Swal.fire({
                title: "Success",
                html: "Topup successful",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary waves-effect waves-light",
                },
                buttonsStyling: false,
              });

              this.props.history.push("/myaccount");
            } else {
              Swal.fire({
                title: "Error",
                html: res.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary waves-effect waves-light",
                },
                buttonsStyling: false,
              });
              this.props.history.push("/refpage/checkout");
            }
          });
      } else {
        window.sessionStorage.setItem("postObject", qs.stringify(postObject));
        if (this.state.paymentMethod === "MSwipe") {
          var post_Object = {
            app_id: unquieID,
            qr_type: this.state.checkoutType,
            customer_id: this.state.UserId,
            payment_type: this.state.checkoutType,
            callbackurl: baseUrl + "placeorder",
            paid_amount: total_amount,
          };

          axios
            .post(
              apiUrl + "paymentV2/createPaymentLink",
              qs.stringify(post_Object),
              headerconfig
            )
            .then((res) => {
              hideLoader("placeOredr");
              if (res.data.status === "ok") {
                var productList = this.state.productList[0];
                localStorage.setItem(
                  "paymentRequestId",
                  res.data.payment_requestId
                );
                localStorage.setItem(
                  "voucherType",
                  productList.product_voucher
                );
                setTimeout(function () {
                  window.location.href = res.data.redirectURL;
                }, 500);
              }
            });
        } else {
          axios
            .post(
              apiUrl + "paymentV2/processReddotPayment",
              qs.stringify(postObject),
              headerconfig
            )
            .then((res) => {
              hideLoader("placeOredr");
              if (res.data.status === "ok") {
                var productList = this.state.productList[0];
                localStorage.setItem(
                  "paymentRequestIdRedtod",
                  res.data.payment_requestId
                );
                localStorage.setItem(
                  "voucherType",
                  productList.product_voucher
                );
                window.location.href = res.data.redirectURL;
              } else {
                showAlert("Alert", res.data.message, "error");
              }
            });
        }
      }
    }else {
      this.setState({error_msg:isErrorMsg})
    }
  }
  selectPaymentMethod(paymentMethod) {
    this.setState({ paymentMethod, runingNum: 0 }, () => {
      clearInterval(downloadTimer);
      if (paymentMethod === "QR Payment") {
        if (this.state.checkoutType === "buyvoucher") {
          let productList = this.state.productList[0];
          this.generateCustQrcode(
            productList.product_primary_id,
            productList.product_company_unique_id
          );
        } else {
          this.generateCustQrcode(this.state.plan_data.topupplan_id);
        }
      }
    });
  }
  render() {
    let planData = this.state.plan_data;
    let planGrandtotal = this.state.plan_grandtotal;
    let planGstAmount = this.state.plan_gst_amount;
    let planSubtotal = this.state.plan_subtotal;
    if (Object.keys(planData).length > 0 || this.state.productList.length > 0) {
      return (
        <div className="main-div">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />

          <div className="mbtm-need rel">
            <div className="container">
              <div className="cart-detail">
                <div className="cart-detail-header">
                  <h3>Your cart details</h3>
                  <a href={() => false} onClick={this.rvmQtyAction.bind(this)}>
                    Clear cart
                  </a>
                </div>
                <div className="cart-detail-body">
                  <div className="cart-detail-voucher">
                    <div className="cart-detail-voucher-img">
                      <figure>
                        <img src={voucyellow} alt="voucyellow" />
                      </figure>{" "}
                      <span>
                        {this.state.checkoutType === "buyvoucher"
                          ? this.state.productName
                          : planData.topupplan_display_name}
                        {this.state.checkoutType === "topup" &&
                          planData?.topupplanFreeVouchers !== "" &&
                          planData?.topupplanFreeVouchers !== null && (
                            <div>
                              <b>Free Voucher: </b>
                              {JSON.parse(planData.topupplanFreeVouchers).map(
                                (vitem, vindex) => {
                                  return (
                                    <label key={vindex}>
                                      {vitem.product.label} X {vitem.quantity}
                                      {vindex !==
                                      JSON.parse(planData.topupplanFreeVouchers)
                                        .length -
                                        1
                                        ? ", "
                                        : ""}
                                    </label>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </span>
                    </div>
                    <div className="cart-detail-voucher-desc">
                      <div className="qty-bx">
                        <span
                          className="qty-minus"
                          onClick={this.plnQtyAction.bind(this, "decr")}
                        >
                          <img src={minus} alt="minus" />
                        </span>
                        <div className="input-quantity">
                          {this.state.plan_qty}
                        </div>
                        <span
                          className="qty-plus"
                          onClick={this.plnQtyAction.bind(this, "incr")}
                        >
                          <img src={plus} alt="plus" />
                        </span>
                      </div>
                      <div className="cart-price">
                        ${planSubtotal.toFixed(2)}
                      </div>
                      <div
                        className="cart-action"
                        onClick={this.rvmQtyAction.bind(this)}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span>Sub Total</span>
                      <strong>
                        <sup>$</sup>
                        {planSubtotal.toFixed(2)}
                      </strong>
                    </li>
                    {parseFloat(planGstAmount) > 0 && (
                      <li>
                        <span>GST</span>
                        <strong>
                          <sup>$</sup>
                          {planGstAmount.toFixed(2)}
                        </strong>
                      </li>
                    )}
                    <li className="ts-total">
                      <span>Total</span>
                      <strong>
                        <sup>$</sup>
                        {planGrandtotal.toFixed(2)}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card-chkinfo">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      onClick={this.checkboxInfoFld.bind(this, "terms")}
                    />
                    <p>
                      I confirm I have read and accept the{" "}
                      <a href={() => false}>Terms & Conditions</a>,{" "}
                      <a href={() => false}>Privacy Policy</a> and the{" "}
                      <a href={() => false}>PDPA consent</a>.
                    </p>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onClick={this.checkboxInfoFld.bind(this, "promotions")}
                    />
                    <p>
                      I'd like to receive news, updates and promotions from
                      Epicpay via email and sms
                    </p>
                  </li>
                  {this.state.paymentMethod === "Reddot" &&
                    this.state.customerData?.showSaveCard === "Yes" && (
                      <li>
                        <input
                          type="checkbox"
                          onClick={this.checkboxInfoFld.bind(this, "savecard")}
                        />
                        <p>Save Your Card details for feature transactions</p>
                      </li>
                    )}
                </ul>
              </div>
              
              <div className="payment-method">
                <ul>
                  {this.state.manualPayment === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "QR Payment"
                          ? "active"
                          : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(
                        this,
                        "QR Payment"
                      )}
                    >
                      <div className="pay-image">
                        <img
                          src={qrcodepay}
                          alt="QR Code Pay"
                          className="qr-code-pay"
                        />
                      </div>
                      <div>QR Payment</div>
                    </li>
                  )}
                  {this.state.reddotmv3ds === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "mv3ds" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "mv3ds")}
                    >
                      <div className="pay-image master-visa">
                        <img
                          src={mastercard}
                          alt="Master"
                          className="master-card"
                        />
                        <img src={visa} alt="Visa" className="visa-card" />
                      </div>
                      <div>Master / Visa Card</div>
                    </li>
                  )}
                  {this.state.reddotamex3ds === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "amex3ds" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "amex3ds")}
                    >
                      <div className="pay-image">
                        <img src={amexPay} alt="Amex Pay" />
                      </div>
                      <div>Amex Pay</div>
                    </li>
                  )}
                  {this.state.reddotgooglepay === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "googlepay" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "googlepay")}
                    >
                      <div className="pay-image">
                        <img src={googlePay} alt="Google Pay" />
                      </div>
                      <div>Google Pay</div>
                    </li>
                  )}                  
                  {this.state.reddotapplepay === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "applepay" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "applepay")}
                    >
                      <div className="pay-image">
                        <img src={applePay} alt="ApplePay" />
                      </div>
                      <div>ApplePay</div>
                    </li>
                  )}
                  {this.state.reddotatome === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "atome" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "atome")}
                    >
                      <div className="pay-image">
                        <img src={atome} alt="Atome Pay" className="attom-pay" />
                      </div>
                      <div>Atome Pay</div>
                    </li>
                  )}
                  {this.state.reddotpaynow === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "paynow" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "paynow")}
                    >
                      <div className="pay-image">
                        <img src={paynow} alt="PayNow" className="pay-img" />
                      </div>
                      <div>PayNow</div>
                    </li>
                  )}
                  {this.state.reddotgrabpay === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "grabpay" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "grabpay")}
                    >
                      <div className="pay-image">
                        <img src={grabpay} alt="GrabPay" className="pay-img" />
                      </div>
                      <div>GrabPay</div>
                    </li>
                  )}

                  {this.state.mswipePayment === true && (
                    <li
                      className={`${
                        this.state.paymentMethod === "MSwipe" ? "active" : ""
                      }`}
                      onClick={this.selectPaymentMethod.bind(this, "MSwipe")}
                    >
                      <div className="pay-image">
                        <img src={mswipe} alt="MSwipe" className="mswipe-pay" />
                      </div>
                      <div>MSwipe</div>
                    </li>
                  )}
                </ul>
              </div>
              {this.state.paymentMethod === "QR Payment" && (
                <div className="card-detail">
                  <div className="cart-detail-header">
                    <h3>Scan QR Code and get amount</h3>
                  </div>
                  <div className="card-detail-body checkout-qr">
                    {this.state.runingNum > 0 ? (
                      <img src={this.state.qrcode_str} alt="QR Code" />
                    ) : (
                      <img
                        src={reloadQr}
                        onClick={this.reloadQrFun.bind(this)}
                        alt="QR Code"
                      />
                    )}

                    {this.state.runingNum > 0 && (
                      <div className="vod-footer">
                        <span>QR Code expire in</span>
                        <h2>{this.state.runingNum}</h2>
                        <p>Seconds</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.paymentMethod !== "QR Payment" && (
            <IonFooter collapse="fade">
              <div className="sticky-single-btn">
                <a
                  href={() => false}
                  className="button green-btn place-order-link"
                  id="placeOredr"
                  onClick={this.proceedPayment.bind(this)}
                >
                  Place Order
                </a>
                <div>
                  <span className="error">{this.state.error_msg}</span>
                </div>
              </div>
            </IonFooter>
          )}
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div class="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  var productlistArr = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
    productlist: productlistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
