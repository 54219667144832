import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import $ from "jquery";
import cookie from "react-cookies";
import Swal from "sweetalert2";
import { GET_CUSTOMER_DETAILS, GET_PRODUCT_LIST } from "../../actions";
import { apiUrl, unquieID, headerconfig, baseUrl } from "../Settings/Config";
import {
  hideLoader,
  showAlert,
  showLoader,
  stripslashes,
  showPriceValue,
  showPriceSeparate,
  showPrice,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import successImg from "../../common/images/image_success.gif";
import voucyellow from "../../common/images/voucher-yellow.svg";

var qs = require("qs");
class Checkout extends Component {
  constructor(props) {
    super(props);
    var UserId = cookie.load("UserId") || "";
    this.state = {
      billPayAmount: "",
      current_page: "Direct Pay",
      checkoutType: "BillPay",
      clientLogo: "",
      backLink: "",
      UserId: UserId,
      customerData: [],

      plan_gst_amount: 0,
      plan_grandtotal: 0,
      inititalLoad: true,
      productList: [],
      productName: "",
      gSettings: "",
      paymentDetail: "",
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
    this.submitRef = React.createRef();

    this.props.getCustomerDetails("&customer_id=" + UserId);
  }

  componentDidMount() {
    $("body").addClass("hide-overlay");
    this.loadSettings();
    this.checkPaymentStatus();
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.customerData !== PropsDt.customerdetails) {
      this.setState({ customerData: PropsDt.customerdetails });
    }
  }

  checkPaymentStatus() {
    var urlParams = new URLSearchParams(this.props.location.search);
    var transactionID = urlParams.get("transaction_id") || "";

    var postObject = {};
    postObject = {
      app_id: unquieID,
      payment_refID: transactionID,
      customer_id: this.state.UserId,
    };
    axios
      .post(
        apiUrl + "paymentV2/checkStatusReddot",
        qs.stringify(postObject),
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ paymentDetail: res.data.billData });
        } else {
           Swal.fire({
            title: "Error",
            html: res.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push("/directpay/checkout");
        }
      });
  }

  loadSettings() {
    var urlShringTxt = apiUrl + "settings/getSettings/?unquieID=" + unquieID;
    axios.get(urlShringTxt, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        var result = res.data.result;

        this.setState({
          gSettings: result,
          clientLogo: result?.company_logo,
        });
      }
    });
  }

  render() {
    if (this.state.gSettings !== "" && this.state.paymentDetail !== "") {
      return (
        <div className="main-div direct-pay direct-pay-thankyou">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />

          <div className="mbtm-need rel">
            <div className="container">
              <div className="cart-detail">
                <div className="cart-detail-header">
                  <h3>Bill details</h3>
                </div>
                <div className="cart-detail-body">
                  <div className="success-img">
                    <img src={successImg} alt="Success" />
                    <h3>Payment Successfully Completed</h3>
                  </div>
                  <div className="cart-detail-voucher">
                    <div className="cart-detail-voucher-img">
                      <figure>
                        <img src={voucyellow} alt="voucyellow" />
                      </figure>{" "}
                      <span>{this.state.paymentDetail.proName}</span>
                    </div>
                    <div className="cart-detail-voucher-desc">
                      <div className="qty-bx"></div>
                      <div className="cart-price">
                        1 X{" "}
                        {showPriceValue(
                          this.state.paymentDetail.subtotal_amount
                        )}
                      </div>
                    </div>
                  </div>
                  <ul>
                  {this.state.paymentDetail.productID!== "" && (
                      <>
                        <li>
                          <span>You Pay</span>
                          <strong>
                            {showPriceSeparate(this.state.paymentDetail?.total_amount)}
                          </strong>
                        </li>
                        {this.state.paymentDetail?.reciveAmount !== "" && (
                          <li>
                            <span>Receive</span>
                            <strong>
                              {showPriceSeparate(this.state.paymentDetail?.reciveAmount||0)}
                            </strong>
                          </li>
                        )}
                      </>
                    )}
                    <li>
                      <span>Bill</span>
                      <strong>
                        {showPriceSeparate(this.state.paymentDetail.billPayAmount)}
                      </strong>
                    </li>
                    {parseFloat(this.state.paymentDetail?.gstAmount||0) > 0 && (
                      <li>
                        <span>GST</span>
                        <strong>
                          {showPriceSeparate(this.state.paymentDetail?.gstAmount)}
                        </strong>
                      </li>
                    )}
                    {this.state.paymentDetail.productID!== "" &&
                      this.state.paymentDetail?.balanceAmount !== "" && (
                        <li>
                          <span>Balance For Next Visit</span>
                          <strong>
                            {showPriceSeparate(this.state.paymentDetail.total_amount-this.state.paymentDetail.billPayAmount)}
                          </strong>
                        </li>
                      )}
                    <li className="ts-total">
                      <span>Total</span>
                      <strong>
                        {showPriceSeparate(
                          this.state.paymentDetail.total_amount
                        )}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
              <IonFooter collapse="fade">
                <div className="sticky-single-btn">
                  <Link
                    to={"/directpay/checkout"}
                    className="button green-btn place-order-link"
                  >
                    Make Another Payment
                  </Link>
                </div>
              </IonFooter>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Header mainpagestate={this.state} prntPagePrps={this.props} />
          <div id="loading-indicator">
            <div class="lds-hourglass"></div>
          </div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var customerdetailsArr = [];
  if (Object.keys(state.customerdetails).length > 0) {
    if (state.customerdetails[0].status === "ok") {
      customerdetailsArr = state.customerdetails[0].result_set;
    }
  }
  var productlistArr = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0].result_set;
    }
  }
  return {
    customerdetails: customerdetailsArr,
    productlist: productlistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAILS, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
