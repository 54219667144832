import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Swal from "sweetalert2";
import { apiUrl, unquieID } from "../Settings/Config";
import { showLoaderLst, hideLoaderLst } from "../Helpers/SettingHelper";
import user from "../../common/images/user.svg";
import homeIcon from "../../common/images/homelg.png";
import nav from "../../common/images/navigation.svg";
import cookie from "react-cookies";
import invoice from "../../common/images/invoice-icon.svg";
import vouc from "../../common/images/voucher-icon.svg";
import wallet from "../../common/images/wallet.svg";
import back from "../../common/images/back-arrow.svg";
import walletlight from "../../common/images/wallet.svg";
import epicpay from "../../common/images/epicpay.png";
var qs = require("qs");
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: "hide",
      enableSubscription: false,
      openMyaccount: false,
      customerInfo: "",
    };
  }

  componentDidMount() {
    var customerId = cookie.load("UserId") || "";
    if (customerId !== "") {
      this.checkAuth(customerId);
      this.checkSubscription(customerId);
      this.getCustomerInfo(customerId);
    }
    $("html, body").animate({
      scrollTop: $("body").offset().top,
    });
  }
  checkAuth(customerId) {
    var urlShringTxt = apiUrl + "customer/checkingauth/?ID=" + customerId;
    axios.get(urlShringTxt, {
      headers: { Authorization: localStorage.getItem("token") },
    }).then((res) => {
      if (res.data.status !== "ok") {
        this.props.prntPagePrps.history.push("/logout");
      }
    });
  }

  checkSubscription(customerId) {
    var urlShringTxt =
      apiUrl +
      "customer/checkSettings/?custID=" +
      customerId +
      "&uniqueID=" +
      unquieID;
    axios.get(urlShringTxt, {
      headers: { Authorization: localStorage.getItem("token") },
    }).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ enableSubscription: res.data.enable_subscription });
      }
    });
  }
  getCustomerInfo(customerId) {
    var urlShringTxt = apiUrl + "customer/customerInfo/?custID=" + customerId;
    axios.get(urlShringTxt, {
      headers: { Authorization: localStorage.getItem("token") },
    }).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ customerInfo: res.data.result });
      }
    });
  }

  openMenuFun(event) {
    event.preventDefault();
    let menuActive = this.state.menuActive;
    if (menuActive === "hide") {
      this.setState({ menuActive: "open" });
      $(".side-bar-maincls").addClass("open");
    } else {
      this.setState({ menuActive: "hide" });
      $(".side-bar-maincls").removeClass("open");
    }
  }

  goToNavPage(activepage, event) {
    event.preventDefault();
    let pageTxt = "";
    var backLink = this.props.mainpagestate?.backLink || "";
    if (backLink !== "") {
      pageTxt = backLink;
    } else if (activepage === "Top Up" || activepage === "PointsRedeem") {
      pageTxt = "myaccount";
    } else if (activepage === "Vouchers") {
      let actionfrom =
        Object.keys(this.props.mainpagestate).length > 0
          ? this.props.mainpagestate.actionfrom
          : "";
      pageTxt = actionfrom === "rewards" ? "rewards" : "myaccount";
    } else if (
      activepage === "VouchersDetail" ||
      activepage === "VouchersRedeem"
    ) {
      pageTxt = "vouchers";
    } else if (activepage === "Subscription Details") {
      pageTxt = "subscription-history";
    }

    this.props.prntPagePrps.history.push("/" + pageTxt);
  }

  showVoucher(tabTxt, event) {
    event.preventDefault();
    cookie.save("vouchers_show", tabTxt, { path: "/" });
    this.props.prntPagePrps.history.push("/vouchers");
  }

  gotoRedeemFun(event) {
    event.preventDefault();
    this.props.history.push("/scanqrcode");
  }
  handleFldChange(event) {
    var customerInfo = this.state.customerInfo;
    customerInfo[event.target.name] = event.target.value;
    this.setState({ customerInfo });
    console.log(
      event.target.name,
      event.target.value,
      customerInfo,
      "event.target.name"
    );
  }
  openAccountForm(e) {
    e.preventDefault();
    this.setState({ openMyaccount: !this.state.openMyaccount });
  }
  updateCustInfo(e) {
    e.preventDefault();
    var customerInfo = this.state.customerInfo;
    var customerId = cookie.load("UserId") || "";
    var postObject = {
      custID: customerId,
      name: customerInfo.customer_first_name,
      email: customerInfo.customer_email,
      phone: customerInfo.customer_phone,
      birthmonth: customerInfo.customer_birthmonth,
    };
    showLoaderLst("cust-upd-btn", "class");
    axios
      .post(
        apiUrl + "customer/updateCustInfo",
        qs.stringify(postObject),
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((res) => {
        hideLoaderLst("cust-upd-btn", "class");
        if (res.data.status === "success") {
          this.setState({ openMyaccount: false }, () => {
            Swal.fire({
              title: "Success",
              html: "Topup successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            });
          });
        } else {
          var $_this = this;
          this.setState({ openMyaccount: false }, () => {
            Swal.fire({
              title: "Error",
              html: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary waves-effect waves-light",
              },
              buttonsStyling: false,
            }).then(function () {
              $_this.setState({ openMyaccount: true });
            });
          });
        }
      });
  }

  render() {
    let activepage =
      Object.keys(this.props.mainpagestate).length > 0
        ? this.props.mainpagestate.current_page
        : "";
    return (
      <>
        <header className="header-main">
          <div className="header-action header-center-txt">
            <div className="container">
              {activepage === "My Account" || activepage === "Epic" ? (
                <>
                  <div className="ha-lhs">
                    {
                      <Link to={"/home"}>
                        <img src={epicpay} alt="Epic Pay" />
                      </Link>
                    }
                  </div>
                </>
              ) : (
                <>
                  <div className="ha-lhs-arrow">
                    {activepage !== "Epic" &&
                      ((this.props.mainpagestate.analyticsType === "" &&
                        this.props.mainpagestate.influencerID === "") ||
                        this.props.mainpagestate.customerId !== "") &&
                      activepage !== "Direct Pay" && (
                        <a
                          href="/"
                          onClick={this.goToNavPage.bind(this, activepage)}
                        >
                          <img src={back} alt="Go Back" />
                        </a>
                      )}
                  </div>

                  <div className="ha-middle">
                    {activepage === "Direct Pay" ? (
                      <>
                        {this.props.mainpagestate.clientLogo !== "" ? (
                          <img src={this.props.mainpagestate.clientLogo} alt="Logo" />
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>{activepage !== "VouchersRedeem" ? activepage : ""}</>
                    )}
                  </div>
                </>
              )}

              <div className="ha-rhs">
                {((this.props.mainpagestate.analyticsType === "" &&
                  this.props.mainpagestate.influencerID === "") ||
                  this.props.mainpagestate.customerId !== "") &&
                  cookie.load("loginType") !== "Guest" && (
                    <ul>
                      {activepage === "My Account" && (
                        <li className="profile-user home-linkcls">
                          <Link
                            to={"/home"}
                            className="home-btn"
                            title="company"
                          >
                            <img src={homeIcon} alt="Home" />
                          </Link>
                        </li>
                      )}
                      <li className="profile-user">
                        <a href="/">
                          <img src={user} alt="user" />
                        </a>
                      </li>
                      <li className="navsbar">
                        <a href="/" onClick={this.openMenuFun.bind(this)}>
                          <img src={nav} alt="nav" />
                        </a>
                      </li>
                    </ul>
                  )}
              </div>
            </div>
          </div>
        </header>

        <div className={"side-bar full-height side-bar-maincls"}>
          <div className="container">
            <div className="three-nav">
              <a
                href="/"
                className="button rv-btn"
                title="Myaccount"
                onClick={this.openAccountForm.bind(this)}
              >
                {" "}
                <img src={walletlight} alt="My Account" /> My Account{" "}
              </a>
              <Link
                to={"/refpage/vouchers"}
                className="button rv-btn"
                title="Buy Product Vouchers"
              >
                {" "}
                <img src={vouc} alt="Voucher" /> Buy Product Vouchers{" "}
              </Link>
              <Link
                to={"/refpage/buy-vouchers"}
                title="Buy Cash Voucher"
                className="button rc-btn"
              >
                <img src={wallet} alt="Buy Cash Voucher" /> Buy Cash Voucher
              </Link>
              <Link
                to={"/refpage/history"}
                title="View History"
                className="button vdt-btn"
              >
                {" "}
                <img src={invoice} alt="View History" /> View History
              </Link>
              {this.state.enableSubscription === true && (
                <Link
                  to={"/refpage/subscription-history"}
                  title="View My Subscription"
                  className="button vdt-btn"
                >
                  {" "}
                  <img src={invoice} alt="View My Subscription" /> View My
                  Subscription
                </Link>
              )}
              <Link to={"/logout"} title="Logout" className="button vdt-btn">
                {" "}
                <img src={invoice} alt="Logout" /> Logout{" "}
              </Link>
            </div>
          </div>
        </div>
        {this.state.customerInfo !== "" && (
          <BottomSheet open={this.state.openMyaccount}>
            <div className="container">
              <div className="update-personal">
                <div className="up-header textcenter">
                  <p>Update your personal details</p>
                </div>
                <div className="up-form">
                  <input
                    type="text"
                    placeholder="Name"
                    name="customer_first_name"
                    value={this.state.customerInfo?.customer_first_name || ""}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="customer_phone"
                    value={this.state.customerInfo?.customer_phone || ""}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="customer_email"
                    value={this.state.customerInfo?.customer_email || ""}
                    onChange={this.handleFldChange.bind(this)}
                  />
                  <select
                    name="customer_birthmonth"
                    value={this.state.customerInfo?.customer_birthmonth || ""}
                    className="components_selct components_selctbox_cls"
                    onChange={this.handleFldChange.bind(this)}
                  >
                    <option value="">Birthday Month</option>
                    <option value="january">January</option>
                    <option value="february">February</option>
                    <option value="march">March</option>
                    <option value="april">April</option>
                    <option value="may">May</option>
                    <option value="june">June</option>
                    <option value="july">July</option>
                    <option value="august">August</option>
                    <option value="september">September</option>
                    <option value="october">October</option>
                    <option value="november">November</option>
                    <option value="december">December</option>
                  </select>
                  {this.state.regcust_error !== "" && (
                    <p className="error_info">{this.state.regcust_error}</p>
                  )}
                  <div className="up-frm-btn">
                    <a
                      href="/"
                      className="button ghost-btn"
                      onClick={this.openAccountForm.bind(this)}
                    >
                      Cancel
                    </a>
                    <a
                      href="/"
                      className="button cust-upd-btn"
                      onClick={this.updateCustInfo.bind(this)}
                    >
                      Update
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BottomSheet>
        )}
      </>
    );
  }
}

export default Header;
